import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import scrollTo from "gatsby-plugin-smoothscroll"
import React, { useEffect } from "react"
import SanitizedHTML from "react-sanitized-html"
import "uswds/dist/css/uswds.min.css"
import "uswds/dist/js/uswds.js"
import Disclaimer from "../components/disclaimer"
import Footer from "../components/footer"
import Header from "../components/header"
import "../components/header.scss"
import ScrollToTop from "../components/scrollToTop"
import faqStyles from "./faq.module.scss"

export default function Faq() {
  const faqData = useStaticQuery(graphql`
    query {
      faq {
        faqTopicSorting {
          fAQTopics {
            contentItemIds
          }
        }
        fAQ {
          displayText
          answer
          sortOrder
          contentItemId
          topic {
            contentItems {
              displayText
            }
          }
        }
      }
      topics {
        topic {
          contentItemId
          displayText
          faqs {
            contentItemIds
          }
        }
      }
    }
  `)
  const faqs = faqData.faq.fAQ
  // JSON.parse(intl.messages.topics)
  const topics = faqData.faq.faqTopicSorting[0].fAQTopics.contentItemIds.map(
    id => faqData.topics.topic.find(topic => topic.contentItemId === id)
  )

  useEffect(() => {
    document.querySelectorAll("a").forEach(link =>
      link.hasAttribute("scroll")
        ? link.addEventListener("click", () => {
            scrollTo(`#${link.getAttribute("scroll")}`)
            document
              .querySelector(
                `#answer-${link.getAttribute("scroll").split("question-")[1]}`
              )
              .removeAttribute("hidden")
            document
              .querySelector(
                `[aria-controls="${`answer-${
                  link.getAttribute("scroll").split("question-")[1]
                }`}"]`
              )
              .setAttribute("aria-expanded", "true")
          })
        : ""
    )
    document.querySelectorAll("a").forEach(link => {
      if (
        link.getAttribute("href") &&
        link.getAttribute("href").includes("http")
      ) {
        link.setAttribute("target", "_blank")
        link.setAttribute("rel", "noopener noreferrer")
      }
    })
  })

  function formatHtml(html) {
    if (html !== null) {
      let formattedHtml = html
        .replace(
          /href="\/Contents\/ContentItems\//g,
          'class="text-primary" style="cursor:pointer; text-decoration: underline;" scroll="question-'
        )
        .replace(/\[image\]R&amp;A_FAQ(.*?)\[\/image\]/g, "")
      return formattedHtml
    } else {
      return
    }
  }

  return (
    <div>
      <Header />
      <main id="main-content">
        <div className="grid-container">
          <section className={faqStyles.card}>
            <h1 className="font-serif-lg tablet:font-serif-2xl margin-0 margin-bottom-3 text-center">
              {" "}
              Recognition and Accreditation Program
            </h1>
            <h1 className="font-serif-lg tablet:font-serif-2xl margin-0 margin-bottom-3 text-center">
              {" "}
              Frequently Asked Questions
            </h1>
            <div className="margin-x-3">
              <p className="margin-x-auto maxw-tablet-lg text-bold">
                These Frequently Asked Questions (FAQs) are being provided as a
                public service and do not constitute legal advice or supersede
                statute, regulations, or case law. For more detailed information
                on requirements, interested parties should consult federal
                regulations (particularly 8 C.F.R. § 1292). 81 Fed. Reg. 92346
                (Dec. 19, 2016).
              </p>
            </div>
            <div className="grid-row display-block">
              <div className={faqStyles.toc}>
                <h2 className="margin-bottom-1">TOPICS</h2>
                <ul className="add-list-reset">
                  {topics.map(topic => {
                    if (topic !== undefined) {
                      return (
                        <li
                          className="margin-bottom-1"
                          key={topic.displayText
                            .replace(/\s+/g, "-")
                            .toLowerCase()}
                        >
                          <a
                            tabIndex="0"
                            className={faqStyles.topic}
                            onClick={() =>
                              scrollTo(
                                `#${topic.displayText
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`
                              )
                            }
                          >
                            {topic.displayText}
                          </a>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
              <div className="faq margin-x-auto maxw-tablet-lg">
                {topics.map(topic => {
                  if (topic !== undefined) {
                    return (
                      <div
                        className="padding-top-2"
                        id={`${topic.displayText
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`}
                        key={topic.displayText
                          .replace(/\s+/g, "-")
                          .toLowerCase()}
                      >
                        <h2 className="margin-top-0">{topic.displayText}</h2>
                        {topic.faqs.contentItemIds
                          .map(id => faqs.find(faq => faq.contentItemId === id))
                          .map(qa => {
                            return (
                              <div
                                className="usa-accordion"
                                aria-multiselectable="true"
                                id={`question-${qa.contentItemId}`}
                                key={qa.displayText
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}
                              >
                                <button
                                  className="usa-accordion__button"
                                  aria-expanded="false"
                                  aria-controls={`answer-${qa.contentItemId}`}
                                >
                                  <h3 className="usa-accordion__heading">
                                    {qa.displayText}
                                  </h3>
                                </button>

                                <div
                                  id={`answer-${qa.contentItemId}`}
                                  className="usa-accordion__content usa-prose"
                                  hidden
                                >
                                  <SanitizedHTML
                                    allowedAttributes={{
                                      a: [
                                        "href",
                                        "scroll",
                                        "class",
                                        "style",
                                        "target",
                                        "rel",
                                      ],
                                      img: ["src", "alt"],
                                      iframe: ["src", "width", "height"],
                                    }}
                                    allowedIframeHostnames={["www.youtube.com"]}
                                    html={formatHtml(qa.answer)}
                                  />
                                  {qa.answer !== null
                                    ? [
                                        ...qa.answer.matchAll(
                                          /(?:\[image\]R&amp;A_FAQ)(.*?)(?:\[\/image\])/g
                                        ),
                                      ]?.map(image => {
                                        return (
                                          <img
                                            src={`${image[1]}`}
                                            alt={`${image[1]}`}
                                            key={`${image[1]}`}
                                          />
                                        )
                                      })
                                    : qa.answer}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </section>
          {/* <SanitizedHTML allowedAttributes={{'a': ['href', 'scroll', 'class', 'style'], 'img': ['src', 'alt'] }} html={'<a href="/hello"> hello</a>'}/> */}
        </div>
      </main>
      <Footer />
      <Disclaimer />
      <ScrollToTop />
    </div>
  )
}
